@tailwind base;
@tailwind components;
@tailwind utilities;


.dark {
    background-color: #111827
    ;
    font-family: 'Vazirmatn', sans-serif;  }


  body{
    font-family: 'Vazirmatn', sans-serif;
  }
  
  
